import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const HistoriePage = () => (
  <Layout>
    <SEO title="Historie" />

    <h1 id="ueberschrift">Allgemeine Informationen zur Entstehung und zum Ausbau des 'Ladens'</h1>
      <p style={{ fontSize: "1em" }}>Eine ausführlichere Beschreibung kann auf Wunsch folgen. Hier ein paar (wenige) Meilensteine:</p>

      <p className="poem">
        "Es ist euch gelungen,<br />
        Ihr habt das Herz mir bezwungen,<br />
        Und die Treue, sie ist doch kein leerer Wahn,<br />
        So nehmet auch mich zum Genossen an<br />
        Ich sei, gewährt mir die Bitte,<br />
        In eurem Bunde der Dritte."
      </p>

      <p style={{ fontSize: "1.2em" }}>
        Friedrich Schiller (1759 - 1805) vollendete die Ballade „Die Bürgschaft“ am 30. August 1798.<br />
        Dies ist ein kleiner Auszug dieses wunderbaren Werkes,<br />
        welches mir über die vielen Hürden in meinem Lebens hilft.
      </p>

    <p>---</p>

    <div class="b-data">

    <h2>2020:</h2>
      <p>
        <b>"Erstens kommt es anders und zweitens als man denkt."</b><br />
        Wilhelm Busch (1832–1908)
      </p>
      <p>
        Corona bremste den Wiederaufbau extrem aus:</p>
      <p>
        Beratung und Gespräche erfolg(t)en nur telefonisch und mehrmals kam die Frage nach dem YouTube-Kanal auf.
        Inzwischen (August) wurde schon wieder etwas getanzt (natürlich mit Abstand und im Freien) und
        das neue Intro für Videos ist fertig.
        Alle sind noch sehr gespannt, wie es nun weitergehen wird...
      </p>
      <p>
        Immerhin wurden privat dank Corona viele Fortschritte (z.B. bei der Hausrenovierung) erzielt :-)
      </p>

      <h2>2019:</h2>
      <p>
        Mehrere private und berufliche Ereignisse (unter anderem die Bauverzögerungen) bewirkten
        Anfang des Jahres den Entschluss, dass das Gewerbe mit sofortiger Wirkung nur noch in der Region in und
        um Nürnberg vertreten sein wird (mit kleinen Ausnahmen).<br />
        Somit wurden die Waren und Kurs- sowie Bastel-Materialien eingelagert, der YouTube-Kanal gelöscht
        und es begann eine umfangreiche Umstrukturierung und eine Art Wiederaufbau.
      </p>
      <p>
        Die letzten freiberuflichen Tätigkeiten konnten nun auch in den Reisegewerbeschein nachgetragen werden.
        Eine weitere freudige Überraschung: per Zufall entstand der erste Line Dance Anfängerkurs. Die Resonanz
        war so gut, sodass am nächsten Tag ein zweiter Kurs gehalten werden konnte.
      </p>
      <p>
        <b>Der Wiederaufbau des Gewerbes dauert noch an. Demzufolge befinden sich auf den einzelnen Seiten entsprechende Hinweise.<br />
        Aktuell kann nur die Beratung und der Kummerkasten in vollem Umfang angeboten werden, während die anderen Gebiete nur
        bedingt und auf Anfrage bedient werden können.</b>
      </p>
      <Link to="/fdml_6a_beratung-klartext/" title="Detaillierte Informationen zum Beratungsangebot" style={{fontSize: "1.5em"}}>zum Klartext der Beratung</Link><br />
      <Link to="//fdml_7a_kummerkasten-klartext/" title="Detaillierte Informationen zum Gesprächsangebot" style={{fontSize: "1.5em"}}>zum Klartext des Kummerkastens</Link>
      <p></p>

      <h2>2018:</h2>
      <p>
        Peu à peu konnten über die Jahre hinweg nun die freiberuflichen Tätigkeiten
        in den Gewerbeschein nachgetragen werden.
      </p>
      <p>
        Es wurde zwar mit den Baggerarbeiten für den Bau des Ladens begonnen,
        doch verwies die Baufirma auf "firmeneigene Schwierigkeiten" und läutete eine Pause ein.
      </p>

      <h2>2017:</h2>
      <p>
        Der Bau eines kleines Ladens in direkter Nähe
        des Bahnhofes Winkelhaid
        wurde geplant, im Sommer in Auftrag gegeben und Herbst begonnen.
      </p>

      <h2>2004-2006:</h2>
      <p>
        Auf Grund privater Ereignisse wurde das Gewerbe "pausiert" und ich habe nur hier und da
        privat (=ohne Umsatz) ein paar Freunde und Stammkunden beraten.
      </p>

      <h2>2000:</h2>
      <p>
        Der Tätigkeitsbereich wurde auf die Schaustellerei erweitert: So fand man mich nun auch unter anderem
        in der Nagelbude, Losbude, Pfeilwerfbude (Spickerbude), etc. oder als Aushilfe bei diversen Fahrgeschäften
        in der Region.
        Dies bot mir neben all den anderen Arbeitsbereichen eine weitere Möglichkeit anonym in beratende Gespräche mit
        den unterschiedlichsten Mitmenschen zu kommen.
      </p>

      <h2>1996:</h2>
      <p>
        Nach vielen Anläufen und Hürden, da etwas "nie Dagewesenes" ins Leben
        gerufen werden sollte, gelang nun schließlich die Anmeldung
        eines Gewerbes: "Fantasie - der mystische Laden" wurde endlich in einer Art "Grundform" akzeptiert.
        ... auch wenn zunächst der Großteil der sogenannten freiberuflichen
        Tätigkeiten außen vor gelassen werden musste und der Reisegewerbe-Schein
        erst ein Jahr später (nach weiteren Gesprächen) folgen konnte, freute ich mich nach den jahrelangen Diskussionen sehr über diesen
        erfolgreichen ersten Schritt.
      </p>
      <p>
        Zu den beratenden und organisatorischen Tätigkeiten integrierte ich das Spielen und Meistern von Rollenspielsystemen
        und begann auf mittelalterlichen Märkten Waren und Dienstleistungen (wie Kettenhemd reparieren und Kleider von Hand
        nähen) anzubieten.
      </p>

      <h2>1993:</h2>
      <p>
        Um trotz der geänderten Arbeits- und Lebensbedingungen die Fixkosten decken zu können
        und um weitere Hürden zu meistern,
        schränkte ich die "beratenden Tätigkeiten" weitmöglichst ein und begann eine
        Ausbildung zur Kauffrau für Bürokommunikation.
      </p>
      <p>
        "Organisatorische Tätigkeiten" übte ich weiterhin aus.
        Auch lernte ich mein erstes "richtiges" Pen&amp;Paper-Rollenspielsystem kennen,
        wodurch ich meinen bestehenden Kurs sofort auf Eis legte.
      </p>

      <h2>1992:</h2>
        <p>
          Auf Grund diverser Ereignisse (unter anderem ein beginnender "Personenkult")
          fällte ich den Entschluss, nur noch in einem
          Umkreis von mindestens 80 km (oder besser 200 km) um Nürnberg herum
          aktiv zu werden. Für die Umsetzung des Planes fanden viele Gespräche
          mit Steuerberatern und mit Mitarbeitern des Gewerbeamtes sowie der IHK statt.
        </p>
        <p>
          Ausnahme: Einige Messen, Veranstaltungen und Konzerte, welche ich inzwischen mit einem
          eingespielten Team (mit-)organisierte, sowie die Koch-Kurse.
        </p>

      <h2>1990/1991:</h2>
        <p>
          Mit großem Vergnügen leitete bzw. organisierte ich diverse Kurse, Informationskreise und Veranstaltungen,
          half meinen Mitmenschen (mit Rat und Tat), machte Musik, tanzte viel, schrieb Romane, kümmerte mich um meine
          Wachtel- und Fisch-Zuchten, half in meines Vaters Spedition (inklusive LKW-Fahren)
          und führte interessante Gespräche mit verschiedenen Ämtern und Fachkräften.
        </p>

      <h2>1989:</h2>
        <p>
          Die ersten Unterlagen zum Kurs "Eigenorganisation: Was ist das? Wie kann ich vorgehen?" wurden erstellt.
          <br />
          Schon bald folgten die "Mitmach-Kurse":
          "Eigenorganisation im Team Familie: <b><u>Wer</u></b> macht <b><u>was</u></b> <b><u>wie</u></b> und vor allem <b><u>wann</u></b>?" und
          "Eigenorganisation bei der Arbeit: <b><u>Wer</u></b> macht <b><u>was</u></b> <b><u>wie</u></b> und vor allem <b><u>wann</u></b>?"
          <br />
          Immer öfter kam es im Anschluss zu Einzel-Gesprächen in denen um Rat gebeten
          oder "einfach Ballast abgeladen" wurde.
          Den Mitmenschen zu helfen bereitete mir stets sehr große Freude. Sogar dann, wenn ich manchmal nur indirekt helfen konnte, indem ich
          an zuständige Fachleute verwies. Aus diesem Grund hielt ich nach immer mehr Möglichkeiten zur Kontaktaufnahme Ausschau.
          Unter anderem nutzte ich z.&nbsp;B. den Umstand in Prinzip dreispachig aufgewachsen zu sein und
          lud zur ersten "Einfach reden"-Gruppe in englischer Sprache ein.
          <br />
          Anonymität war stets mein oberstes Gebot.
        </p>
        <p>
          Am 31.10.1089 fand die erste von mir organisierte Veranstaltung statt: Eine Halloween-Feier im damaligen KOMM in Nürnberg.
          Bei keiner anderen Veranstaltung lernte ich mehr, da ich bei dieser wohl fast jeden "Fehler" gemacht habe,
          den man machen kann.
        </p>

      <h2>1987:</h2>
        <p>
          Die ersten DOS-Anwendungen (mit DBase-Datenbanken) liefen zur vollsten Zufriedenheit
          und hier und da hielt ich meine ersten Referate
          mit mehr oder minder großen Erfolgen. Meine anschließenden Fragen zu der Art
          der Berichterstattung irritierten die Zuhörer. Sie brachten
          mir dennoch viele gute Ratschläge ein.
        </p>
        <p>
          Erste Anfragen beim Gewerbe- und Finanzamt und meinem Vater brachten nur kleine Erfolge, da mein Haupt-Interessengebiet zu den freiberuflichen
          Tätigkeiten zählte. Die von mir geplante Mischung der Arbeitsgebiete stieß zu der damaligen Zeit noch auf Unverständnis.
        </p>

      <h2>ca. ab 1980:</h2>
        <p>
          Immer wieder suchten Klassenkameraden und Freunde das Vier-Augen-Gespräch. Sei es wegen
          Unstimmigkeiten mit Freunden, Lehrern oder gar innerhalb der Familie.
          Des Weiteren wurde immer wieder betont, wie gut organisiert ich sei (vor allem
          bezüglich Hausaufgaben, diverser Wahlfächer und Nebenjobs).
          Diese und weitere Ereignisse weckten mein Interesse und ich begann mich
          bereits während der Schulzeit (unter anderem)
          in die Psychologie einzulesen. Ich erweiterte mein theoretisches
          Wissen und übte die praktische Umsetzung und Anwendung
          der Kenntnisse durch Gespräche mit Psychiatern und anderen Fachkundigen. 
        </p>
        <p>
          Darüber hinaus wurde mir mitgeteilt, dass ich einen "Pferde- und Hunde-Verstand" hätte. Dies
          hatte zur Folge, dass ich immer öfter bei Schwierigkeiten mit jenen Vierbeinern
          hinzu gerufen wurde.
        </p>
        <p>
          Unabhängig von meinen zahlreichen Wahlfächern, in denen ich mir sehr unterschiedliches Wissen
          aneignete, half ich beim örtlichen Bauernhof aus, schrieb zwei Bücher (1 Bilderbuch und 1 Comic,
          welche ich selber druckte, band und Verwandten schenkte),
          besuchte eine Ballett- und zwei Musikschulen, bastelte an diversen eigenen Gedichten und Liedern und lernte
          die Arbeitsabläufe in der Spedition meines Vaters kennen. Letzteres verschaffte mir auch das Privileg, mich schon sehr früh
          mit der EDV zu befassen.
        </p>
    </div>
  </Layout>
)

export default HistoriePage
